<script>
  /**
   * Shipment details with a header and thumbnails of line items. Used in the market checkout confirmation page.
   */
  export default {
    props: {
      /** An array of objects. */
      lineItems: {
        type: Array,
        required: true,
      },
    },
  }
</script>

<template>
  <div>
    <div class="flex items-center">
      <!-- @slot Header. -->
      <slot />
      <span class="font-normal pl-1">
        ({{ lineItems.reduce((qty, item) => (qty += item.quantity), 0) }}
        {{ lineItems.reduce((qty, item) => (qty += item.quantity), 0) > 1 ? 'items' : 'item' }})
      </span>
      <BaseIconClickable
        :size="3"
        label="tooltip"
        color="black"
        class="inline-block align-middle mb-1px"
        @click="$emit('tooltip')"
      >
        <IconInfoAlertOutline />
      </BaseIconClickable>
    </div>

    <ul class="mt-2 md:pr-9 flex flex-wrap">
      <li v-for="(item, itemIndex) in lineItems" :key="itemIndex" class="mr-2 mt-2">
        <BaseImage
          :src="item.variant.previewCardImages[0]"
          :alt="item.variant.title"
          responsive="xs"
          sizes="128px"
          class="w-14"
        />
      </li>
    </ul>
  </div>
</template>
