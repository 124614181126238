<script>
  
  import ShipmentDetails from '@/components/checkout/summary/ShipmentDetails'
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'
  import screen from '@/helpers/screen'
  import HelpMessaging from '@/components/app/HelpMessaging.vue'

  export default {
    components: {
      ShipmentDetails,
      HomeWidgetSingleEntryModal,
      ShippingModal,
      HelpMessaging,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials', 'upsell'].includes(value),
      },
    },
    data() {
      return {
        orderTotal: 0,
        shippingAddress: {},
        hasStandardDelivery: true,
        hasRushShipping: false,
        standardItems: null,
        holidayItems: null,
        tooltipModalOpen: false,
        modalContent: {
          type: 'trending',
          slug: 'holiday-delivery',
        },
        shippingModalVisible: false,
      }
    },
    computed: {
      screen,
      cart() {
        return this.storeType === 'upsell' ? 'upsell' : `${this.storeType}Cart` // upsell, marketplaceCart, essentialsCart
      },
      standardQuantity() {
        return this.standardItems?.length
      },
      holidayQuantity() {
        return this.holidayItems?.length
      },
      liveMarket() {
        return this.storeType === 'marketplace' ? { name: 'Market' } : { name: 'EssentialsHome' }
      },
    },
    created() {
      // save the data before we clear the cart
      this.orderTotal = this.$store.get(`${this.cart}/cartTotal`)
      this.shippingAddress = this.$store.get(`${this.cart}/selectedShippingAddress`)
      this.hasRushShipping = this.$store.get(`${this.cart}/rushShipping`)

      const finalLineItems = this.$store.get(`${this.cart}/finalLineItems`)

      this.holidayItems = finalLineItems.filter(({ variant }) => variant.rushShippingStock)
      this.standardItems = this.hasRushShipping
        ? finalLineItems.filter(({ variant }) => !variant.rushShippingStock)
        : finalLineItems

      // if user opted in for rush shipping and only has rush shipping items, no standard delivery
      if (this.hasRushShipping && !this.standardQuantity) {
        this.hasStandardDelivery = false
      }

      // this is the one place where the cart cleared
      this.$store.dispatch(`${this.cart}/clearCart`)
      this.$store.set(`${this.cart}/successfulOrder`, false)
    },
    methods: {
      toggleTooltipModal(slug) {
        this.tooltipModalOpen = !this.tooltipModalOpen
        if (slug) {
          this.modalContent = {
            type: 'trending',
            slug: slug,
          }
        }
      },
    },
  }
</script>

<template>
  <section class="py-15 sm:py-20">
    <BaseContainer max-width="md">
      <div class="text-center">
        <h1 class="font-heading font-medium" :style="{ fontSize: $pxToRem(40), lineHeight: 1.3 }">
          Order Confirmed!
        </h1>
        <p class="mt-3 md:text-sm px-2 md:px-22">
          Thank you for shopping consciously with us! We are processing your order and will email
          you when your order has shipped. We’re so grateful to have you in the Alltrue Community.
        </p>
      </div>

      <div
        class="mt-10 md:mt-8 border-t-1 border-b-1 border-gray-active divide-y divide-gray-active"
      >
        <div class="flex justify-between py-4 text-lg md:grid md:grid-cols-3 font-semibold">
          <div> Order total </div>
          {{ $formatPrice(orderTotal) }}
        </div>
        <div class="order-details">
          <div class="mb-4 sm:mb-0 font-semibold">Shipping address</div>
          <div class="ml-3 sm:ml-0 text-sm leading-tight">
            <BaseAddress :address="shippingAddress" include-name />
          </div>
        </div>
        <div class="order-details font-semibold">
          <div class="mb-4 sm:mb-0">Shipment details</div>
          <div class="text-sm leading-tight col-span-2">
            <ShipmentDetails
              v-if="hasStandardDelivery"
              :line-items="standardItems"
              @tooltip="shippingModalVisible = true"
            >
              Standard Delivery
            </ShipmentDetails>
            <BaseDivider v-if="hasStandardDelivery && hasRushShipping" class="my-4" />
            <ShipmentDetails
              v-if="hasRushShipping"
              :line-items="holidayItems"
              @tooltip="toggleTooltipModal('holiday-delivery')"
            >
              <BaseIcon :size="5" class="inline-block align-middle mt-1 mr-1">
                <IconRushShipping />
              </BaseIcon>
              Holiday Delivery
            </ShipmentDetails>
          </div>
        </div>
      </div>

      <div class="mt-10 sm:mt-14 mb-8 xs:flex xs:justify-center">
        <div class="xs:w-74 xs:max-w-full">
          <BaseButton v-if="storeType === 'upsell'" :to="{ name: 'Home' }">
            Visit Account
          </BaseButton>
          <BaseButton v-else :to="liveMarket">Continue shopping</BaseButton>
        </div>
      </div>
      <div class="text-center leading-tight text-xs need-help-links">
        <HelpMessaging />
      </div>
    </BaseContainer>

    <ShippingModal
      :open="shippingModalVisible"
      :type="storeType === 'marketplace' ? 'seasonal' : storeType"
      @dismiss="shippingModalVisible = false"
    />

    <HomeWidgetSingleEntryModal
      :open="tooltipModalOpen"
      :update="modalContent"
      override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
      @dismiss="toggleTooltipModal"
    />
  </section>
</template>

<style lang="postcss">
  .order-details {
    @apply flex flex-col py-4 text-lg;

    @screen md {
      @apply grid grid-cols-3;
    }
  }
</style>
